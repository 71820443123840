//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PdfViewer from "vue-pdf";
import Dialog from "@/view/components/Dialog";
export default {
  name: "pdf-viewer-dialog",
  props: {
    pdfDialog: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  watch: {
    pdfDialog: function pdfDialog() {
      this.page_no = 1;
      this.total_page = 0;
    }
  },
  data: function data() {
    return {
      page_no: 1,
      total_page: 0
    };
  },
  methods: {
    prev_page: function prev_page() {
      if (this.page_no > 1) {
        this.page_no = this.page_no - 1;
      }
    },
    next_page: function next_page() {
      if (this.page_no < this.total_page) {
        this.page_no = this.page_no + 1;
      }
    },
    is_disabled: function is_disabled(type) {
      if (this.loading) {
        return true;
      }

      if (type == "prev") {
        if (this.page_no < 2) {
          return true;
        }
      }

      if (type == "next") {
        if (this.page_no >= this.total_page) {
          return true;
        }
      }

      return false;
    },
    update_page_no: function update_page_no(param) {
      this.page_no = param;
    },
    update_total_page: function update_total_page(param) {
      this.total_page = param;
    },
    init: function init() {}
  },
  components: {
    "viewer-dialog": Dialog,
    "pdf-viewer": PdfViewer
  },
  mounted: function mounted() {
    this.init();
  }
};